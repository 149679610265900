var myState = null;
var myMenu = [];
var myApp = null;
var myScope = null;
var hasInit = 0;

microflow.controller("microflowCtrl", [
  "$controller",
  "$scope",
  "$route",
  "$routeParams",
  "$location",
  "$http",
  "microflowFac",
  "authenFac",
  "$translate",
  "$timeout",
  "$parse",
  "iam2",
  function(
    $controller,
    $scope,
    $route,
    $routeParams,
    $location,
    $http,
    microflowFac,
    authenFac,
    $translate,
    $timeout,
    $parse,
    iam2
  ) {
    try {
      angular.extend(this, $controller("ActionController", { $scope: $scope }));
    } catch (err) {
      console.error(err);
    }

    $scope.InputMapping = microFlowInputMapping;
    $scope.microFlowKey = microFlowKey;
    $scope.pageHist = [];
    $scope.http = $http;

    myScope = $scope;
    myScope.http = $http;
    $scope.workingPage = "";
    $scope.routeParams = $routeParams;

    $scope.appInfo = { title: "" };
    myApp = $scope.appInfo;

    $scope.State = {};
    $scope.Store = [];
    $scope.Page = {};
    $scope.$Q = {};
    $scope.$V = {};

    $scope.update = function() {
      if (!$scope.$$phase) {
        $scope.$apply();
      }
    };
    $scope.initial = function() {
      try {
        if ($route.current) {
          var pageName = $route.current.myPageName;
          $scope.__currentPage = pageName;
          if (window.PAGE_STATE_SCHEMA.hasOwnProperty(pageName)) {
            var pageStateLs = window.PAGE_STATE_SCHEMA[pageName];
            if (pageStateLs) {
              pageStateLs.forEach(function(state) {
                try {
                  var rgx = RegExp(/\".*\"$/g);
                  if (rgx.test(state.propName)) {
                    $scope.State[state.paramName] = eval(state.propName) || "";
                  } else {
                    $scope.State[state.paramName] = decodeURI(state.propName);
                  }
                } catch (error) {
                  $scope.State[state.paramName] = "";
                }
              });
            }
          }
        }
        // $scope.$V = {};
        // if (window.PAGE_VARIABLE.hasOwnProperty(pageName)) {
        //   var pageVariableLs = window.PAGE_VARIABLE[pageName];
        //   pageVariableLs.forEach(function(object) {
        //     var value = null;
        //     if (object.defaultValue) {
        //       if (object.variableType == "Number") {
        //         value = Number(object.defaultValue);
        //       } else {
        //         value = object.defaultValue;
        //       }
        //     }
        //     if (object.expression) {
        //       var getExpression = $parse(object.expression);
        //       value = getExpression($scope);
        //     }
        //     $scope.$V[object.variableName] = value;
        //   });
        // }
        $scope.$Q = $location.search();
        for (var qKey in $scope.$Q) {
          $scope.State[qKey] = $scope.$Q[qKey];
        }
        $scope.update();
      } catch (error) {
        console.error(error);
      }
    };
    $scope.initial();
    $scope.changeLanguage = function(key) {
      if (typeof key === "string") {
        key = key.toLowerCase();
      }
      $translate.use(key);
    };
    $scope.getMenuName = function(inPage) {
      var menuName = "";
      for (var i = 0; i < routeConfigList.length; i++) {
        var objRoute = routeConfigList[i];
        if (objRoute.routeName === inPage) {
          menuName = objRoute.menuName;
          break;
        }
      }
      return menuName;
    };

    $scope.menuList = [];
    for (var i = 0; i < routeConfigList.length; i++) {
      var objRoute = routeConfigList[i];
      if (objRoute.onMenu === "Y") {
        var objMenu = {
          menuName: objRoute.menuName,
          stateName: objRoute.routeName
        };
        $scope.menuList.push(objMenu);
      }
    }
    myMenu = $scope.menuList;

    $scope.getPathByPage = function(inPage) {
      var result = "";
      for (var i = 0; i < routeConfigList.length; i++) {
        var objRoute = routeConfigList[i];
        if (objRoute.routeName === inPage) {
          result = objRoute.urlRoute;
          break;
        }
      }
      return result;
    };
    $scope.redirectUrl = function(url, target) {
      var targetName = "_self";
      if (target) {
        targetName = target;
      }
      window.open(url, targetName);
    };
    $scope.stateGo = function(url, query) {
      hideAllModal(function() {
        $scope.pageHist.push(
          $location.path().substring(1, $location.path().length)
        );
        $scope.appInfo.title = $scope.getMenuName(url);
        $scope.setLastState();
        changeLocation(url);
        var path = $scope.getPathByPage(url);
        $location.path(path).search(query || "");
        $scope.update();
      });
    };
    $scope.pageGo = $scope.stateGo;
    $scope.stateBack = function() {
      var backPage = $scope.pageHist.pop();
      $location.path(backPage);
      $scope.update();
    };
    $scope.pageBack = $scope.stateBack;
    $scope.parseQueryParams = function(queryStr) {
      try {
        var queryObj = JSON.parse(queryStr);
        var names = Object.getOwnPropertyNames(queryObj);
        var queryURL = names.reduce(function(r, next) {
          return (
            r + `&${next}=${$parse(queryObj[next])($scope) || queryObj[next]}`
          );
        }, "_t=1");
        return queryURL;
      } catch (e) {
        return "";
      }
    };
    $scope.injectScopeToObject = function(paramObj) {
      try {
        if (paramObj instanceof Object) {
          var rs = {};
          for (const prop in paramObj) {
            rs[prop] = $parse(paramObj[prop])($scope);
          }
          return rs;
        }
        return {};
      } catch (error) {
        console.error(error);
        return {};
      }
    };
    $scope.setLastState = function() {
      var flowList = microflowFac.flowList;
      for (var key in flowList) {
        var pageName = flowList[key].pageName;
        if ($scope.myPageName == pageName) {
          flowList[key].mappingStateToPG($scope.State);
        }
      }
    };

    $scope.getPageName = function() {
      return $scope.__currentPage;
    };
    $scope.toggleElement = function(el) {
      try {
        el.classList.toggle("ng-hide");
      } catch (error) {
        console.error(error);
      }
    };
    $scope.getState = function(stateName) {
      try {
        if (stateName) {
          if ($scope.State.hasOwnProperty(stateName)) {
            return $scope.State[stateName];
          }
        } else {
          return $scope.State;
        }
      } catch (error) {
        console.error(error, stateName);
      }
    };
    $scope.setState = function() {
      var argsLength = arguments.length;
      var callback;
      var isParent = false;
      try {
        if (argsLength) {
          if (argsLength == 2) {
            var arg2 = arguments[1];
            if (typeof arg2 === "boolean") {
              isParent = arg2;
            }
            if (typeof arg2 === "function") {
              callback = arg2;
            }
          } else if (argsLength == 3) {
            if (typeof arguments[1] === "boolean") {
              isParent = arguments[1];
            }
            callback = arguments[2];
          }
          var argState = arguments[0];
          var stateObj;
          if (typeof argState === "function") {
            var stateObj = argState();
          } else {
            stateObj = argState;
          }
          if (isParent) {
            Object.assign($scope.$parent.State, stateObj);
          } else {
            Object.assign($scope.State, stateObj);
          }
          $scope.update();
          if (typeof callback === "function") {
            callback($scope);
          }
        }
      } catch (error) {
        console.error(error);
        if (typeof callback === "function") {
          callback($scope, error);
        }
      }
    };
    $scope.getStore = function(mfProcessName, fieldValue) {
      try {
        var storeValue;
        if (mfProcessName) {
          storeValue = $scope.Store[mfProcessName];
          if (fieldValue) {
            storeValue = eval(`storeValue.${fieldValue}`);
          }
        } else {
          return $scope.Store;
        }
        return storeValue;
      } catch (error) {
        console.error(error, mfProcessName, fieldValue);
      }
    };
    $scope.openModal = function(modalId, callback) {
      try {
        if (modalId && $(`#${modalId}`).length > 0) {
          var modal = $(`#${modalId}`);
          modal.on("shown.bs.modal", function() {
            if (typeof callback === "function") {
              callback($scope);
            }
          });
          modal.modal("show");
        } else {
          throw new Error("Modal not found.");
        }
      } catch (error) {
        console.error(error);
        if (typeof callback === "function") {
          callback($scope, error);
        }
      }
    };
    $scope.closeModal = function(modalId, callback) {
      try {
        if (modalId && $(`#${modalId}`).length > 0) {
          var modal = $(`#${modalId}`);
          modal.on("hidden.bs.modal", function(e) {
            if (typeof callback === "function") {
              callback($scope);
            }
          });
        } else {
          throw new Error("Modal not found.");
        }
        modal.modal("hide");
      } catch (error) {
        console.error(error);
        if (typeof callback === "function") {
          callback($scope, error);
        }
      }
    };
    $scope.closeAllModal = function(callback) {
      try {
        var modalList = $(".modal.show");
        if (modalList.length > 0) {
          modalList.on("hidden.bs.modal", function(e) {
            if (typeof callback === "function") {
              callback($scope);
            }
          });
        } else {
          throw new Error("Modal not found.");
        }
        modalList.modal("hide");
      } catch (error) {
        console.error(error);
        if (typeof callback === "function") {
          callback($scope, error);
        }
      }
    };
    $scope.doFlowAction = function(mfProcessName, data, callback) {
      try {
        if (mfProcessName) {
          if (data instanceof Object) {
            var dataFlow = {
              flowName: mfProcessName,
              object: data
            };
            microflowFac.doFlowAction(dataFlow, $scope).then(
              function(data) {
                if (typeof callback === "function") callback($scope, data);
              },
              function(error) {
                throw new Error(error);
              }
            );
          } else {
            throw new Error("Invalid data is not object.");
          }
        } else {
          throw new Error("Invalid microflow process name.");
        }
      } catch (error) {
        console.error(error);
        if (typeof callback === "function") callback($scope, null, error);
      }
    };
    $scope.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === "[object Array]";
    };
    $scope.isObject = function(arg) {
      return Object.prototype.toString.call(arg) === "[object Object]";
    };
    $scope.setCookieIAM2Token = iam2.setCookieIAM2Token;
    $scope.removeCookieIAM2Token = iam2.removeCookieIAM2Token;
    $scope.getIAM2Permissions = iam2.getIAM2Permissions;
    $scope.setPermissions = iam2.setPermissions;
    $scope.getPermissions = iam2.getPermissions;
    $scope.clearPermissions = iam2.clearPermissions;
  }
]);
function changeLocation(url) {
  $(window).trigger("popstate");
}
function hideAllModal(callback) {
  var modalList = $(".modal.show");
  if (modalList.length > 0) {
    modalList.on("hidden.bs.modal", function(e) {
      if (typeof callback === "function") {
        callback();
      }
    });
  } else {
    if (typeof callback === "function") {
      callback();
    }
  }
  modalList.modal("hide");
}
